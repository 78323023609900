<template>
  <div>
    <el-dialog :title="this.params.data ? '编辑' : '添加'" :visible.sync="params.show" fullscreen  :before-close="close" size="60%" :with-header="false" @open="openDialog">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px">
        <el-form-item label="语言">
          <el-switch
            v-model="isEnglish"
            active-text="英文"
            inactive-text="中文">
          </el-switch>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择">
            <el-option
              v-for="item in typeValue"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌" prop="type">
          <el-select v-model="ruleForm.brand_id" placeholder="请选择">
            <el-option
              v-for="item in brandSelectList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封面" prop="cover_path">
          <image-upload v-model="ruleForm.cover_path"></image-upload>
        </el-form-item>

        <el-form-item v-show="!isEnglish" label="标题(中文)" prop="title_cn">
          <el-input v-model="ruleForm.title_cn" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item v-show="isEnglish" label="标题(英文)" prop="title_us">
          <el-input v-model="ruleForm.title_us" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item v-show="!isEnglish" label="副标题(中文)" prop="subhead_cn">
          <el-input v-model="ruleForm.subhead_cn" placeholder="请输入副标题"></el-input>
        </el-form-item>
        <el-form-item v-show="isEnglish" label="副标题(英文)" prop="subhead_us">
          <el-input v-model="ruleForm.subhead_us" placeholder="请输入副标题"></el-input>
        </el-form-item>

        <el-form-item v-show="!isEnglish" label="产品介绍(中文)" prop="introduce_cn">
          <tinymce v-model="ruleForm.introduce_cn"></tinymce>
        </el-form-item>
        <el-form-item v-show="isEnglish" label="产品介绍(英文)" prop="introduce_us">
          <tinymce v-model="ruleForm.introduce_us"></tinymce>
        </el-form-item>

        <el-form-item v-show="!isEnglish" label="功能特性(中文)" prop="peculiarity_cn">
          <tinymce v-model="ruleForm.peculiarity_cn"></tinymce>
        </el-form-item>
        <el-form-item v-show="isEnglish" label="功能特性(英文)" prop="peculiarity_us">
          <tinymce v-model="ruleForm.peculiarity_us"></tinymce>
        </el-form-item>

        <el-form-item v-show="!isEnglish" label="产品规格(中文)" prop="specification_cn">
          <tinymce v-model="ruleForm.specification_cn"></tinymce>
        </el-form-item>
        <el-form-item v-show="isEnglish" label="产品规格(英文)" prop="specification_us">
          <tinymce v-model="ruleForm.specification_us"></tinymce>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submit">确定</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { editProduct, addProduct } from '@/api/product/product'
import { mapState, mapActions } from 'vuex'
import tinymce from '@/components/common/tinymce.vue'
import imageUpload from '@/components/imageupload/image-upload.vue'
export default {
  components: {
    tinymce,
    imageUpload
  },
  data() {
    return {
      isEnglish: false,
      typeValue: [
        {
          label: '服务器',
          value: 1
        },
        {
          label: '存储',
          value: 2
        },
        {
          label: '网络传输',
          value: 3
        },
        {
          label: '周边配件',
          value: 4
        }
      ],
      ruleForm: {
        id: null,
        type: 1,
        brand_id: '',
        title_cn: '',
        title_us: '',
        subhead_cn: '',
        subhead_us: '',
        cover_path: '',
        introduce_cn: '',
        introduce_us: '',
        peculiarity_cn: '',
        peculiarity_us: '',
        specification_cn: '',
        specification_us: '',
      },
      rules: {}
    }
  },
  props: {
    params: {
      required: true,
      type: Object,
    }
  },
  methods: {
    ...mapActions('brand', ['getBrandSelectList']),
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.ruleForm = {
        id: null,
        type: 1,
        brand_id: '',
        title_cn: '',
        title_us: '',
        subhead_cn: '',
        subhead_us: '',
        cover_path: '',
        introduce_cn: '',
        introduce_us: '',
        peculiarity_cn: '',
        peculiarity_us: '',
        specification_cn: '',
        specification_us: '',
      }
      this.$emit('close')
    },
    async openDialog () {
      this.isEnglish = false
      this.getBrandSelectList({
        cur: 1,
        size: 100
      })
      if (this.params.data) {
        this.ruleForm = {
          id: this.params.data.id,
          type: this.params.data.type,
          brand_id: this.params.data.brand_id,
          title_cn: this.params.data.title_cn,
          title_us: this.params.data.title_us,
          subhead_cn: this.params.data.subhead_cn || '',
          subhead_us: this.params.data.subhead_us || '',
          cover_path: this.params.data.cover_path,
          introduce_cn: this.params.data.introduce_cn || '',
          introduce_us: this.params.data.introduce_us || '',
          peculiarity_cn: this.params.data.peculiarity_cn || '',
          peculiarity_us: this.params.data.peculiarity_us || '',
          specification_cn: this.params.data.specification_cn || '',
          specification_us: this.params.data.specification_us || '',
        }
        this.rules = {}
      } else {
        this.rules = {
          type: [
            { required: true, message: '请输选择类型', trigger: 'blur' },
          ],
          title_cn: [
            { required: true, message: '请输入标题(中文)', trigger: 'blur' },
            { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' },
          ],
          title_us: [
            { required: true, message: '请输入标题(英文)', trigger: 'blur' },
            { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' },
          ],
          cover_path: [
            { required: true, message: '请上传封面', trigger: 'blur' },
          ],
          introduce_cn: [
            { required: true, message: '请输入产品介绍(中文)', trigger: 'blur' },
          ],
          introduce_us: [
            { required: true, message: '请输入产品介绍(英文)', trigger: 'blur' },
          ],
          peculiarity_cn: [
            { required: true, message: '请输入功能特性(中文)', trigger: 'blur' },
          ],
          peculiarity_us: [
            { required: true, message: '请输入功能特性(英文)', trigger: 'blur' },
          ],
          specification_cn: [
            { required: true, message: '请输入产品规格(中文)', trigger: 'blur' },
          ],
          specification_us: [
            { required: true, message: '请输入产品规格(英文)', trigger: 'blur' },
          ],
        }
      }
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) {
          this.$message.error('请检查是否完成所有必填项！')
          return
        }
        const result = this.params.data ? await editProduct(this.ruleForm) : await addProduct(this.ruleForm)
        if (result.success) {
          this.$message.success('成功')
          this.close()
        }
      })
    },
  },
  computed: {
    ...mapState('brand', ['brandSelectList'])
  },
}
</script>
<style scoped lang="less">

</style>
