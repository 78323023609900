<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>产品管理</el-breadcrumb-item>
    </el-breadcrumb>
    
    <el-row>
      <el-col :span="24">
        <el-select @change="selectChange" v-model="queryInfo.type" placeholder="请选择">
          <el-option
            v-for="item in typeValue"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click="addClick">添加</el-button>
      </el-col>
    </el-row>

    <table-ui
      :propList="propList"
      :operationProps="operationProps"
      :listData="productList"
      v-bind="otherProps"
      :listCount="productTotal"
      v-model="queryInfo"
      @page="pageChange"
    >
      <template #typeSlot="scope">{{ scope.row.type | productTypeFormat }}</template>
      <template #coverSlot="scope">
        <el-image slot="error" :src="scope.row.cover_path" >
          <i class="el-icon-picture-outline"></i>
        </el-image>
      </template>
      <template #operation="scope">
        <span v-if="solution_id" @click="addbindSolution(scope.row)" style="margin-right: 10px;color: rgb(73, 73, 214);cursor: pointer;">关联</span>
        <span @click="lookClick(scope.row)" style="margin-right: 10px;cursor: pointer;">查看主图</span>
        <span @click="editClick(scope.row)" style="margin-right: 10px;cursor: pointer;">编辑</span>
        <el-popconfirm title="确定删除？" @confirm="deleteClick(scope.row.id)">
          <span slot="reference" style="color: red;cursor: pointer;">删除</span>
        </el-popconfirm>
      </template>
    </table-ui>

    <add-edit-productn-dialog :params="addEditInfo" @close="addEditDialogClose"></add-edit-productn-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import TableUi from '@/components/table-ui/table-ui.vue'
import { propList, operationProps, otherProps } from './config/productConfig.js'
import { productDetail, deleteProduct } from '@/api/product/product'
import addEditProductnDialog from './cnps/add-edit-product-dialog.vue'
import { addSolutionProductBind } from '@/api/solution/solution'
export default {
  data() {
    return {
      propList,
      operationProps,
      otherProps,
      typeValue: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '服务器',
          value: 1
        },
        {
          label: '存储',
          value: 2
        },
        {
          label: '网络传输',
          value: 3
        },
        {
          label: '周边配件',
          value: 4
        }
      ],
      addEditInfo: {
        show: false,
        data: null,
      },
      solution_id: null,
    }
  },
  components: {
    TableUi,
    addEditProductnDialog
  },
  mounted() {
    this.solution_id = this.$route.query.solution_id
    this.getProductList()
  },
  computed: {
    ...mapState('product', ['productList', 'productTotal', 'queryInfo'])
  },
  methods: {
    ...mapActions('product', ['getProductList']),
    ...mapMutations('product', ['changeQueryInfo']),
    selectChange() {
      this.changeQueryInfo({ cur: 1 })
      this.getProductList()
    },
    pageChange(ev) {
      this.changeQueryInfo({ cur: ev.cur, size: ev.size })
      this.getProductList()
    },
    // 关闭窗口
    addEditDialogClose () {
      this.addEditInfo.show = false;
      this.addEditInfo.data = null;
      this.getProductList()
    },
    // 增加
    addClick() {
      this.addEditInfo.show = true
    },
     // 编辑
     async editClick(row) {
      const details = await productDetail({ id: row.id })
      if (!details.success) return
      this.addEditInfo.data = details.data
      this.addEditInfo.show = true
    },
    // 删除
    async deleteClick(id) {
      const result = await deleteProduct(id)
      if (result.success) {
        this.$message.success('删除成功')
        this.getProductList()
      }
    },
    // 查看轮播图
    async lookClick(row) {
      this.$router.push({
        path: '/main/product/slideshow',
        query: {
          id: row.id,
        }
      })
    },
    // 关联到解决方案
    async addbindSolution (row) {
      const result = await addSolutionProductBind({
        product_id: row.id,
        solution_id: this.solution_id,
      })
      if (result.success) {
        this.$message.success('成功')
      }
    }
  },
  
}
</script>

<style lang="less" scoped>
 .el-button {
    margin-left: 10px;
  }
</style>