<template>
  <div class="table_ui">
    <el-table border :data="listData" @selection-change="handleSelectionChange" tooltip-effect="dark">
      <el-table-column type="expand" v-if="operationProps.expand">
        <template slot-scope="props">
          <slot name="expandSlot" :row="props.row">
            <el-table border :data="props.row[operationProps.expandKey || 'itemList']" tooltip-effect="dark" class="expand-row-content">
              <el-table-column v-for="expandPropItem in expandPropList" v-bind="expandPropItem" :key="expandPropItem.webId">
                <template #default="props" v-if="expandPropItem.slotName">
                  <slot :name="expandPropItem.slotName" :row="props.row">
                    {{ props.row[expandPropItem.prop] }}
                  </slot>
                </template>
                <template #default="props" v-else-if="expandPropItem.copy">
                  <div class="table-column-content">
                    <span>{{ props.row[expandPropItem.prop] }}</span>
                    <i
                      v-show="props.row[expandPropItem.prop]"
                      title="复制"
                      @click="copyClick(props.row[expandPropItem.prop])"
                      class="el-icon-copy-document"
                    ></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </slot>
        </template>
      </el-table-column>
      <el-table-column type="selection" align="center" width="60" v-if="showSelectColumn"></el-table-column>
      <template v-for="propItem in propList">
        <el-table-column v-bind="propItem" v-if="isShow(propItem.webId)" :key="propItem.webId">
          <template #default="scope" v-if="propItem.slotName">
            <slot :name="propItem.slotName" :row="scope.row">
              {{ scope.row[propItem.prop] }}
            </slot>
          </template>
          <template #default="scope" v-else-if="propItem.dateFormat">
            <div class="table-column-content">
              {{ scope.row[propItem.prop] | dateTimeFormat }}
            </div>
          </template>
          <template #default="scope" v-else-if="propItem.copy">
            <div class="table-column-content">
              <span>{{ scope.row[propItem.prop] }}</span>
              <i v-show="scope.row[propItem.prop]" title="复制" @click="copyClick(scope.row[propItem.prop])" class="el-icon-copy-document"></i>
            </div>
          </template>
        </el-table-column>
      </template>
      <el-table-column width="auto" min-width="1" class="cloumn"></el-table-column>
      <template v-if="operationProps.isShow">
        <el-table-column :width="operationProps.width || 100" :align="operationProps.align || 'center'" fixed="right">
          <template slot="header">
            <el-popover placement="bottom" width="100" trigger="hover">
              <el-checkbox-group v-model="operationProps.checkedCities">
                <ul style="padding: 0">
                  <li v-for="item in operationProps.checkeList" :key="item.id">
                    <el-checkbox :label="item.id">{{ item.label }}</el-checkbox>
                  </li>
                </ul>
              </el-checkbox-group>
              <p slot="reference" style="text-align: center; width: 30px; margin: 0 auto">...</p>
            </el-popover>
          </template>
          <template #default="scope">
            <slot name="operation" :row="scope.row"> ... </slot>
          </template>
          <!-- <template #default2="scope">
            <slot name="orgs" :row="scope.row"> ... </slot>
          </template> -->
        </el-table-column>
      </template>
    </el-table>
    <div class="footer" v-if="showFooter">
      <slot name="footer">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.cur"
          :page-size="page.size"
          :page-sizes="[10, 20, 30]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listCount"
        >
        </el-pagination>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'page',
    event: 'page'
  },
  props: {
    listData: {
      type: Array,
      require: true
    },
    propList: {
      type: Array,
      require: true
    },
    expandPropList: {
      type: Array,
      require: true
    },
    operationProps: {
      type: Object,
      require: true
    },
    showSelectColumn: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    showIndex: {
      type: Boolean,
      default: false
    },
    page: {
      type: Object,
      default: () => ({ current: 0, size: 0, total: 0 })
    },
    listCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    isShow(id) {
      if (!this.operationProps || !this.operationProps.isShow) {
        return true
      } else {
        if (this.operationProps.checkedCities.indexOf(id) >= 0) {
          return true
        } else {
          return false
        }
      }
    },
    handleSelectionChange(value) {
      this.$emit('selectionChange', value)
    },
    handleSizeChange(size) {
      this.$emit('page', { ...this.page, cur: 1, size })
    },
    handleCurrentChange(current) {
      this.$emit('page', { ...this.page, size: this.page.size, cur: current })
    },
    // 复制文字
    copyClick(text) {
      try {
        const el = document.createElement('input')
        el.setAttribute('value', text)
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        this.$message.success('已复制')
      } catch (err) {
        this.$message.error(err.message)
        throw err
      }
    }
  }
}
</script>
<style scoped lang="less">
.footer {
  margin-top: 15px;

  .el-pagination {
    text-align: right;
  }
}
.cloumn {
  border: none !important;
}
.table-column-content {
  i {
    margin-left: 3px;
    cursor: pointer;
  }
}
.expand-row-content {
  margin: 10px 160px 30px 70px;
  width: calc(100% - 160px - 70px);
}
.table_ui {
  margin-top: 10px;
}
</style>
